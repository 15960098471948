import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import SignIn from "./pages/signIn";
import FileList from "./pages/fileList";
import CameraDetail from "./pages/cameraDetail";
import {AIModelHeaders, ApplicationHeaders, FirmwareHeaders} from "./util/getFields"
import TopToolBar from "./components/toolBar";
import UnitDetail from "./pages/unitDetail";
import FileCreate from "./pages/fileCreate";
import AccountList from "./pages/accountList";
import AccountDetail from "./pages/accountDetail";
import UnitList from "./pages/unitList";
import UnitCreate from "./pages/unitCreate";
import AccountCreate from "./pages/accountCreate";
import FileDetail from "./pages/fileDetail";
import CameraCreate from "./pages/cameraCreate";
import {aiModelFields, applicationFields, firmwareFields} from "./util/modelFields";
import FAQPage from "./pages/FAQ";
import ValidateEmail from "./pages/emailValidation";
import EnumProvider from "./components/enumContext";
import OrganizationDetail from "./pages/orgDetail";
import RefreshManager from "./components/refreshManager";

function App() {
    return (
        <EnumProvider>
            <BrowserRouter>
                <RefreshManager/>
                <Routes>
                    <Route path="/" element={<TopToolBar/>}>
                        <Route index element={<UnitList/>}/>

                        <Route path="unit" element={<UnitList/>}/>
                        <Route path="unit/new" element={<UnitCreate/>}/>
                        <Route path="unit/:id" element={<UnitDetail/>}/>

                        {/*<Route path="camera" element={<CameraList/>}/>*/}
                        <Route path="camera/new" element={<CameraCreate navigation/>}/>
                        <Route path="camera/:id" element={<CameraDetail/>}/>

                        <Route path="firmware" element={<FileList
                            alias="firmware"
                            fileHeaders={FirmwareHeaders}
                        />}/>
                        <Route path="firmware/new" element={<FileCreate
                            alias="firmware"
                            fields={firmwareFields}
                            navigation
                        />}/>
                        <Route path="firmware/:id" element={<FileDetail alias='firmware'/>}/>

                        <Route path="aimodel" element={<FileList
                            alias="aimodel"
                            fileHeaders={AIModelHeaders}
                        />}/>
                        <Route path="aimodel/new" element={<FileCreate
                            alias="aimodel"
                            fields={aiModelFields}
                            navigation
                        />}/>
                        <Route path="aimodel/:id" element={<FileDetail alias='aimodel'/>}/>

                        <Route path="application" element={<FileList
                            alias="application"
                            fileHeaders={ApplicationHeaders}
                        />}/>
                        <Route path="application/new" element={<FileCreate
                            alias="application"
                            fields={applicationFields}
                            navigation
                        />}/>
                        <Route path="application/:id" element={<FileDetail alias='application'/>}/>

                        <Route path="account" element={<AccountList/>}/>
                        <Route path="account/new" element={<AccountCreate/>}/>
                        <Route path="account/:id" element={<AccountDetail/>}/>

                        <Route path="organization" element={<OrganizationDetail/>}/>

                        <Route path="help" element={<FAQPage/>}/>
                        <Route path="*" element={<Navigate replace to="/"/>}/>
                    </Route>
                    <Route path="/login" element={<SignIn/>}/>
                    <Route path="/validate" element={<ValidateEmail/>}/>
                </Routes>
            </BrowserRouter>
        </EnumProvider>
    );

}

export default App;
