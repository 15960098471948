import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {Divider, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import RemoveIcon from '@mui/icons-material/Remove';

const PropertyRow = ({nameChange, valueChange, descriptionChange, isError, prefix, values}) => <>
    <TextField
        fullWidth
        margin='normal'
        required
        name='name'
        label='Name'
        id='name'
        error={isError(`${prefix}_name`)}
        value={values[`${prefix}_name`]}
        onChange={nameChange}
        sx={{maxWidth: 350, mr: 2}}/>
    <TextField
        fullWidth
        margin='normal'
        required
        name='value'
        label='Value'
        id='value'
        error={isError(`${prefix}_value`)}
        value={values[`${prefix}_value`]}
        onChange={valueChange}
        sx={{maxWidth: 350, mr: 2}}/>
    <TextField
        fullWidth
        margin='normal'
        name='description'
        label='Description'
        id='description'
        value={values[`${prefix}_description`]}
        onChange={descriptionChange}
        sx={{maxWidth: 350}}/>
</>

export default function Properties({display, setData, setError, data, prefix}) {
    const [rows, setRows] = useState(Object.keys(data).length);

    useEffect(() => {
        setError(Object.values(data).reduce((acc, item, _) =>
            (acc || Object.values(item).reduce((ac, value, _) => (ac || value === ''), false)), false))
    }, [data]);

    const onChange = (index, key, e) => {
        setData(r => ({
            ...r,
            [String(index)]: {
                ...r[String(index)],
                [key]: e.target.value
            }
        }))
    }

    const isError = (index, key) => {
        return data[String(index)] && !data[String(index)][key];
    }

    const addRow = () => {
        setData(d => ({...d, [String(rows)]: {[`${prefix}_name`]: '', [`${prefix}_value`]: ''}}));
        setRows(rows + 1);
    }

    const removeRow = () => {
        const newData = {...data};
        delete newData[String(rows - 1)];
        setData(newData);
        setRows(rows - 1);

    }

    return <>
        <Typography variant='subtitle1'>Add New {display} Additional Information</Typography>
        <Button startIcon={<AddIcon/>} onClick={addRow}>Add Row</Button>
        <Divider/>
        {Array.from({length: rows}).map((_, index) => (
            [
                <PropertyRow
                    nameChange={(e) => onChange(index, `${prefix}_name`, e)}
                    valueChange={(e) => onChange(index, `${prefix}_value`, e)}
                    descriptionChange={(e) => onChange(index, `${prefix}_description`, e)}
                    isError={(key) => isError(index, key)}
                    prefix={prefix}
                    values={Object.entries(data)[index]}
                    key={`property-row-${index}`}
                />,
                <Divider variant="middle" sx={{opacity: 0.6, mt: 1, mb: 1}} key={`property-divider-${index}`}/>
            ]
        ))}
        <br/>
        {rows > 0 &&
            <Button startIcon={<RemoveIcon/>} onClick={removeRow}>Remove Row</Button>
        }
    </>
}
