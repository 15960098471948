import {routes, usePostData} from "../routes/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Grid, Typography} from "@mui/material";
import {unitFields} from "../util/modelFields";
import InputField from "../components/inputField";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import {LoadingComponent} from "../util/loading";
import RelationView from "../components/relationView";
import {getCreateFields, getRequiredFields, isDict} from "../util/util";
import ErrorDisplay from "../components/errorDisplay";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";

export default function UnitCreate() {
    const token = Cookies.get('token');
    const unitRoute = routes.unit;
    const requiredFields = getRequiredFields(unitFields.fields);
    const fields = getCreateFields(unitFields.fields);

    const [unitFormdata, setUnitFormdata] = useState(Object.values(unitFields.fields).filter(item => item.defaultValue).reduce((acc, item) => ({
        ...acc,
        [item.alias]: item.defaultValue
    }), {}));
    const [touched, setTouched] = useState({});
    const [error, setError] = useState(false);
    const [fieldChecks, setFieldChecks] = useState({});
    const [alert, setAlert] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        data: unitPostResp,
        statusCode: unitPostStatus,
        handleCall: postUnit
    } = usePostData(token, unitRoute);

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from ? location.state.from : {pathname: "/"};

    useEffect(() => {
        const regexError = Object.values(fieldChecks).reduce((acc, item, _) => (acc || item), false);
        setError(requiredFields.reduce((acc, item, _) => (acc || (!unitFormdata[item])), false) || regexError);
    }, [requiredFields, unitFormdata])

    const handleUnitChange = (e, regexp) => {
        if (!e.target.value) {
            const formData = {...unitFormdata};
            delete formData[e.target.name];
            setUnitFormdata(formData);
        } else {
            setUnitFormdata({
                ...unitFormdata,
                [e.target.name]: e.target.value
            });
            if (regexp) {
                setFieldChecks({
                    ...fieldChecks,
                    [e.target.name]: !regexp.test(e.target.value)
                });
            }
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleDateChange = (key, value) => {
        setUnitFormdata({
            ...unitFormdata,
            [key]: value.format('YYYY-MM-DD')
        })
    }

    const handleEnumChange = (e) => {
        setUnitFormdata({
            ...unitFormdata,
            [e.target.name]: e.target.value
        })
    }

    const handleRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setUnitFormdata({
                    ...unitFormdata,
                    [key]: newValue.map((item) => item[0])
                });
            }
        } else if (!newValue) {
            const tempForm = {...unitFormdata};
            delete tempForm[key];
            setUnitFormdata(tempForm);
        } else {
            setUnitFormdata({
                ...unitFormdata,
                [key]: newValue[0]
            });
        }
    }

    const handleNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setUnitFormdata({
                ...unitFormdata,
                [key]: value
            });
        } else {
            if (unitFormdata[key]) {
                if (unitFormdata[key].length < limit) {
                    setUnitFormdata({
                        ...unitFormdata,
                        [key]: [...unitFormdata[key], value]
                    });
                }
            } else {
                setUnitFormdata({
                    ...unitFormdata,
                    [key]: [value]
                });
            }
        }
    }

    const handleSubmitUnit = async () => {
        setLoading(true);
        await postUnit({body: unitFormdata});
        setLoading(false);
    }

    useEffect(() => {
        if (unitPostStatus >= 200 && unitPostStatus < 300) {
            setAlert(false);
            navigate(from);
        } else if (unitPostStatus > 300) {
            setAlert(true);
            setErrorFields(isDict(unitPostResp) ? Object.keys(unitPostResp) : []);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [unitPostStatus, unitPostResp]);

    const onBlur = (e) => {
        setTouched({...touched, [e.target.name]: true});
    }
    if (loading) return <LoadingComponent/>

    return (
        <Grid container spacing={2} alignItems="baseline" justifyContent="flex-start"
              sx={{margin: 'auto', mt: 5, width: '80%'}}>
            <Grid item xs={12}>
                <ErrorDisplay
                    alert={alert}
                    setAlert={setAlert}
                    response={unitPostResp}
                    fields={unitFields}
                    statusCode={unitPostStatus}
                    account
                />
            </Grid>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='unit' display='Unit' create/>
            </Grid>
            <Grid item xs={12} sx={{mt: 5}}>
                <Typography variant='h4'>New Unit</Typography>
            </Grid>
            {fields.map((field) => (
                <Grid item xs={12} md={6} xl={3} key={`${field.alias}-field`}
                      sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <InputField field={field}
                                error={errorFields.includes(field.alias)}
                                onChange={handleUnitChange}
                                onDateChange={handleDateChange}
                                onEnumChange={handleEnumChange}
                                onBlur={onBlur}
                                value={unitFormdata[field.alias]}
                                regexCheck={fieldChecks}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <RelationView
                    alias='unit'
                    fields={unitFields.relations}
                    handleChange={handleRelationChange}
                    handleNew={handleNew}
                    currentValue={unitFormdata}
                />
            </Grid>
            <Grid item xs={12} sx={{mt: 10}}>
                <Button variant="contained" onClick={handleSubmitUnit} disabled={error}>Submit</Button>
            </Grid>
        </Grid>
    )
}