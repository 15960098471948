import {Breadcrumbs, Typography} from "@mui/material";
import Link from '@mui/material/Link';
import {Link as RouterLink, useLocation} from 'react-router-dom';

export default function NavigateBreadcrumbs({alias, display, create, id, unitSN, unitId, cameraSN, cameraId}) {
    const location = useLocation();
    if (!create && !id) {
        return <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
            <Link component={RouterLink} underline="hover" color="inherit" to='/' state={{from: location}}>
                Home
            </Link>
            <Typography color="text.secondary">{display}s</Typography>
        </Breadcrumbs>
    }

    if (unitId && !cameraId) {
        return <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
            <Link component={RouterLink} underline="hover" color="inherit" to='/' state={{from: {...location, pathname: '/unit'}}}>
                Home
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to='/unit' state={{from: location, pathname: '/unit'}}>
                Units
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to={`/unit/${unitId}`}
                   state={{from: location}}>
                Unit {unitSN}
            </Link>
            <Typography color="text.secondary">{`${display} ${id}`}</Typography>
        </Breadcrumbs>
    }

    if (!unitId && cameraId) {
        return <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
            < Link component={RouterLink} underline="hover" color="inherit" to='/' state={{from: {...location, pathname: '/camera'}}}>
                Home
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to='/unit' state={{from: {...location, pathname: '/camera'}}}>
                Units
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to={`/camera/${cameraId}`}
                   state={{from: location}}>
                Camera {cameraSN}
            </Link>
            <Typography color="text.secondary">{`${display} ${id}`}</Typography>
        </Breadcrumbs>
    }

    if (unitId && cameraId) {
        return <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
            < Link component={RouterLink} underline="hover" color="inherit" to='/' state={{from: {...location, pathname: '/unit'}}}>
                Home
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to='/unit' state={{from: {...location, pathname: '/unit'}}}>
                Units
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to={`/unit/${unitId}`}
                   state={{from: location}}>
                Unit {unitSN}
            </Link>
            < Link component={RouterLink} underline="hover" color="inherit" to={`/camera/${cameraId}`}
                   state={{from: location}}>
                Camera {cameraSN}
            </Link>
            <Typography color="text.secondary">{`${display} ${id}`}</Typography>
        </Breadcrumbs>
    }

    return <Breadcrumbs aria-label="breadcrumb" maxItems={4}>
        < Link component={RouterLink} underline="hover" color="inherit" to='/' state={{from: {...location, pathname: '/unit'}}}>
            Home
        </Link>
        < Link component={RouterLink} underline="hover" color="inherit" to={`/${alias === 'camera' ? 'unit' : alias}`}
               state={{...location, pathname: '/unit'}}>
            {alias === 'camera' ? 'Unit' : display}s
        </Link>
        {create &&
            <Typography color="text.secondary">New {alias === 'camera' ? 'Camera' : display}</Typography>
        }
        {id &&
            <Typography color="text.secondary">{id}</Typography>

        }
    </Breadcrumbs>
}