import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import Button from "@mui/material/Button";

export default function CustomToolbar({handleReset}) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <Button
                variant="text"
                color="primary"
                onClick={handleReset}
                startIcon={<RotateLeftOutlinedIcon/>}
            >
                Reset Columns
            </Button>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}