import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Cookies from "js-cookie";
import {LoadingComponent} from "../util/loading";
import {useLogOut} from "../routes/api";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import VideocamIcon from '@mui/icons-material/Videocam';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import FilterIcon from '@mui/icons-material/Filter';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Tooltip from "@mui/material/Tooltip";

import logo from "../assets/logo/as_logo_small_w.png";
import bg from "../assets/logo/as_logo_med_10.png";
import ContactUs from "./contactUs";

const navigateItems = [
    {text: 'Units', path: '/unit', icon: <VideocamIcon/>, allUser: true},
    // {text: 'Cameras', path: '/camera', icon: <CameraAltIcon/>, allUser: true},
    {text: 'Firmware', path: '/firmware', icon: <WysiwygIcon/>, allUser: false},
    {text: 'AI Model', path: '/aimodel', icon: <FilterIcon/>, allUser: false},
    {text: 'Application', path: '/application', icon: <SettingsApplicationsRoundedIcon/>, allUser: false},
    {text: 'Account', path: '/account', icon: <AccountBoxIcon/>, allUser: true},
    {text: 'Organization', path: '/organization', icon: <AccountBalanceIcon/>, allUser: true},
    {text: 'FAQ', path: '/help', icon: <HelpCenterIcon/>, allUser: true},
]

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiPaper-root': {
            backgroundColor: '#385480',
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerListItemButton = styled(ListItemButton)(({theme}) => ({
    '&.Mui-selected': {
        backgroundColor: '#6884b0',
        '&:hover': {
            backgroundColor: '#7894c0',
        },
    },
    '&:hover': {
        backgroundColor: '#486490',
    },
}))

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: 25,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        display: 'flex',
        minHeight: "100vh",
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: '95% 95%',

        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        }),
    }),
);


export default function TopToolBar() {
    const token = Cookies.get('token');
    const admin = Cookies.get('webAdmin') === 'T';
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [clicked, setClicked] = useState(!!token);
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const {handleCall} = useLogOut(token);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        setLoading(true);
        setClicked(false);
        Cookies.remove('token');
        Cookies.remove('permissions');
        Cookies.remove('username');
        await handleCall();
        setClicked(false);
        setLoading(false);
    }

    const handleLogin = () => {
        navigate('/login');
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    const selected = (item) => {
        if (location.pathname === '/' && item.text === 'Units') {
            return true;
        }
        if (location.pathname.split('/')[1] === 'camera' && item.path.split('/')[1] === 'unit') {
            return true;
        }
        return (location.pathname.split('/')[1] === item.path.split('/')[1])
    }


    useEffect(() => {
        if (!token) navigate('/login');
    }, [token]);

    if (loading) return <LoadingComponent/>

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Drawer
                        variant='permanent'
                        open={open}
                        onClose={toggleDrawer(false)}
                    >
                        <DrawerHeader>
                            <IconButton onClick={toggleDrawer(!open)}>
                                {open ? <ChevronLeftIcon sx={{color: '#A8C4F0'}}/> :
                                    <ChevronRightIcon sx={{color: '#A8C4F0'}}/>}
                            </IconButton>
                        </DrawerHeader>
                        <Box
                            sx={{width: 250}}
                            role="presentation"
                            onKeyDown={toggleDrawer(false)}
                        >
                            <List>
                                {navigateItems.filter((item) => (admin || item.allUser)).map((item, index) => (
                                        <ListItem key={item.text} disablePadding sx={{display: 'block'}}>
                                            <DrawerListItemButton
                                                key={index}
                                                component={Link}
                                                to={item.path}
                                                selected={selected(item)}
                                                state={{from: location}}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    color: '#e9f9fe'
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        color: '#e9f9fe'
                                                    }}
                                                >
                                                    {open ?
                                                        item.icon :
                                                        <Tooltip title={item.text} arrow>{item.icon}</Tooltip>}
                                                </ListItemIcon>
                                                <ListItemText primary={item.text}
                                                              sx={{ml: open ? 1 : 3, opacity: open ? 1 : 0}}/>
                                            </DrawerListItemButton>
                                        </ListItem>
                                    )
                                )}

                            </List>
                        </Box>
                    </Drawer>
                    <Link to="/">
                        <Box
                            component="img"
                            src={logo}
                            alt="Leopard Imaging"
                            sx={{height: 40, marginRight: 2}}
                        />
                    </Link>
                    <Box sx={{flexGrow: 1}}/>
                    {/*<Typography*/}
                    {/*    component={Link}*/}
                    {/*    to={`${BASE_URL}/api/docs/`}*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noopener noreferrer"*/}
                    {/*    variant="subtitle1"*/}
                    {/*    style={{textDecoration: 'none', color: 'inherit'}}*/}
                    {/*    sx={{mr: 2}}*/}
                    {/*    margin*/}
                    {/*>*/}
                    {/*    Developer Portal*/}
                    {/*</Typography>*/}

                    {!clicked ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                    ) : (<Button
                            onClick={handleOpenUserMenu}
                            key='Avatar'
                            sx={{typography: "subtitle1", textTransform: "none", color: 'white'}}
                            startIcon={<Avatar
                                alt={Cookies.get('username')}
                                children={Cookies.get('username')[0].toUpperCase()}
                                sx={{bgcolor: '#19AB91'}}/>}
                        >
                            {Cookies.get('username')}
                        </Button>
                    )}
                    <Box sx={{flexGrow: 0}}>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key="Logout" onClick={handleLogout}>
                                <Typography
                                    textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Main open={open}>
                <Outlet/>
                <Box sx={{mt: 25, mb: 5}}>
                    <ContactUs/>
                </Box>
            </Main>
            {/*<DeviceUpdateAlert/>*/}
        </Box>
    )
}