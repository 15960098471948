import Cookies from "js-cookie";
import {routes, useGetDataList, usePatchData} from "../routes/api";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Autocomplete, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PopupDialog from "./popupDialog";
import {relationContent} from "../util/util";
import Properties from "./property";
import ErrorDisplay from "./errorDisplay";

export default function RelationEdit({
    fields,
    alias,
    display,
    id,
    relation,
    refresh,
    setEdit,
    defaultValue,
    addSnackbar
}) {
    const token = Cookies.get('token');
    const patchRoute = routes[alias];
    const getRoute = routes[relation.toLowerCase()];
    const relationField = fields.relations[relation];

    const [detail, setDetail] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [editedRelation, setEditedRelation] = useState({
        [relationField.alias]: defaultValue ?
            (relationField.multiple ? defaultValue.map((item) => item[0]) : defaultValue[0]) :
            (relationField.multiple ? [] : null)
    });
    const [properties, setProperties] = useState({});
    const [propertyError, setPropertyError] = useState(false);
    const [alert, setAlert] = useState(false)

    const {data, loading, handleCall} = useGetDataList(token, getRoute);
    const {
        data: patchResp,
        statusCode: patchStatus,
        handleCall: handlePatch
    } = usePatchData(token, patchRoute, id);

    const fetchData = async () => {
        try {
            await handleCall({params: {free: 'T', basic: 'T'}});
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (JSON.stringify(data) !== '{}') {
            if (defaultValue){
                setDetail(relationField.multiple ? filterDuplicates([...defaultValue, ...data]) : filterDuplicates([defaultValue, ...data]));
            }else {
                setDetail(data);
            }
        }
    }, [data]);

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleDialogOpen = () => {
        setOpenDialog(true);
    }

    const handleRelationChange = (newValue) => {
        if (relationField.multiple) {
            if (!relationField.limit || !newValue || newValue.length <= relationField.limit) {
                setEditedRelation({
                    ...editedRelation,
                    [relationField.alias]: newValue.map((item) => item[0])
                });
            }
        } else if (!newValue) {
            setEditedRelation({
                ...editedRelation,
                [relationField.alias]: null
            });
        } else {
            setEditedRelation({
                ...editedRelation,
                [relationField.alias]: newValue[0]
            });
        }
    }

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = async () => {
        let body = editedRelation;
        if (relationField.is_property && Object.values(properties).length > 0) {
            body = {...body, new_property: Object.values(properties)}
        }
        await handlePatch({body: body});
    };
    const filterDuplicates = (array) => {
        return Array.from(
            new Set(array.map((item) => JSON.stringify(item)))
        ).map((item) => JSON.parse(item));
    };

    const getValue = () => {
        if (!editedRelation[relationField.alias]) {
            return relationField.multiple ? [] : null;
        }
        if (relationField.multiple) {
            return editedRelation[relationField.alias].map(item => detail.find(option => option[0] === item)).filter(item => item);
        } else {
            return detail.find(option => option[0] === editedRelation[relationField.alias]) || null;
        }
    }

    useEffect(() => {
        if (patchStatus >= 200 && patchStatus < 300) {
            setAlert(false);
            setEdit(false);
            if (refresh) {
                refresh();
            }
            if (addSnackbar) {
                addSnackbar(`Updating ${relationField.display}`, fields.display, '10 minutes')
            }
        } else if (patchStatus > 300) {
            setAlert(true);
        }
    }, [patchStatus, patchResp]);

    const handleRelationRefresh = async () => {
        setOpenDialog(false);
        await handleCall({params: {free: 'T', basic: 'T'}});
    }

    return <Grid container
                 spacing={2}
                 alignItems="center"
                 sx={{mb: 5}}
    >
        <Grid item xs={12}>
            <ErrorDisplay
                alert={alert}
                setAlert={setAlert}
                response={patchResp}
                fields={fields}
                statusCode={patchStatus}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant='h6'>Edit {relationField.display}</Typography>
        </Grid>
        <Grid item xs={6}>
            <Autocomplete
                id={alias}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                label={alias}
                name={alias}
                isOptionEqualToValue={(option, v) => option[0] === v[0]}
                getOptionLabel={(option) => option[1]}
                value={getValue()}
                options={detail}
                onChange={(e, newValue) => {
                    handleRelationChange(newValue);
                }}
                loading={loading}
                multiple={relationField.multiple}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={relationField.display}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        helperText={relationField.limit && `${relationField.display} limited up to ${relationField.limit}`}
                    />
                )}
            />
        </Grid>
        {relationContent[relationField.model] &&
            <>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={handleDialogOpen}>
                        New
                    </Button>
                </Grid>
                <Grid item xs={2}/>
                <PopupDialog
                    open={openDialog}
                    display={relationField.display}
                    content={relationContent[relationField.model](null, () => handleRelationRefresh())}
                    handleClose={handleDialogClose}
                />
            </>
        }
        {relationField.is_property &&
            <Grid item xs={12}>
                <Properties
                    display={display}
                    setData={setProperties}
                    data={properties}
                    setError={setPropertyError}
                    prefix={relationField.prefix}
                />
            </Grid>
        }
        <Grid item xs={2}>
            <Button component="label"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={propertyError}
                    sx={{margin: 2}}>
                Submit
            </Button>
        </Grid>
    </Grid>
}