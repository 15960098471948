import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function RefreshManager() {
    const location = useLocation();
    const model = location.pathname.split('/')[1];
    const id = location.pathname.split('/')[2];

    useEffect(() => {
        if (!(model === 'camera' && id)) {
            sessionStorage.removeItem('objects');
            sessionStorage.removeItem('timeRange');
        }

        const savedScrollPosition = sessionStorage.getItem('scrollPosition');

        if (savedScrollPosition && model === 'camera') {
            const {x, y} = JSON.parse(savedScrollPosition);

            setTimeout(() => {
                window.scrollTo(x, y);
            }, 700);
            setTimeout(() => {
                window.scrollTo(x, y);
            }, 1500);
        } else {
            window.scrollTo(0, 0);
            sessionStorage.removeItem("scrollPosition");
        }

        const handleBeforeUnload = () => {
            if (model === 'camera') {
                sessionStorage.setItem('scrollPosition', JSON.stringify({x: window.scrollX, y: window.scrollY}));
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [location]);
};