import Cookies from "js-cookie";
import {fileUrl, routes, useGetDataById} from "../routes/api";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {fileModel, updateData} from "../util/util";
import {LoadingComponent} from "../util/loading";
import {Card, CardContent, CardHeader, Grid, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ContentDetail from "../components/contentDetail";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Typography from "@mui/material/Typography";
import PopupDialog from "../components/popupDialog";
import RelationEdit from "../components/relationEdit";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {DeleteComponent} from "../components/confirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ErrorDisplay from "../components/errorDisplay";
import {grey} from "@mui/material/colors";
import {EnumContext} from "../components/enumContext";


export default function FileDetail({alias}) {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ? JSON.parse(Cookies.get('permissions'))[alias] : {create: false, edit: false, delete: false};
    const {id} = useParams();
    const route = routes[alias];
    const fileHeaders = fileModel[alias].fileHeaders;
    const fields = fileModel[alias].fields;
    const property = fileModel[alias].property;
    const {enums} = useContext(EnumContext);

    const [newData, setNewData] = useState({});
    const [edit, setEdit] = useState(false);
    const [popup, setPopup] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});

    const {data: detail, loading, statusCode, handleCall} = useGetDataById(token, route, id);

    const navigate = useNavigate();
    const location = useLocation();

    const unitId = location.state?.unitId ? location.state.unitId : null;
    const unitSN = location.state?.unitSN ? location.state.unitSN : null;

    const cameraId = location.state?.cameraId ? location.state.cameraId : null;
    const cameraSN = location.state?.cameraSN ? location.state.cameraSN : null;

    const page = location.state?.page ? location.state.page : null;

    useEffect(() => {
        if (statusCode > 400) {
            navigate(`/${alias}`, {state: {page: page}});
        }
    }, [statusCode]);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchDetail();
    }, []);

    useEffect(() => {
        if (JSON.stringify(detail) !== '{}') {
            const updatedData = updateData(detail.data, fileHeaders, fileModel[alias].prefix, enums);
            const updated = {
                ...detail, data: updatedData
            }
            setNewData(updated);
        }
    }, [detail]);

    const handleDownload = () => {
        const url = fileUrl(detail.data[`${fileModel[alias].prefix}_content`]);
        const fileName = 'file.pdf'; // Desired file name

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = '_blank';

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    if (loading || Object.keys(newData).length === 0) return <LoadingComponent/>

    return <Grid container spacing={2} alignItems="stretch" justifyContent="center"
                 sx={{margin: 'auto', mt: 5, width: '80%'}}>
        <Grid item xs={12}>
            <ErrorDisplay
                fields={fileModel[alias].fields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
        </Grid>
        <Grid item xs={12}>
            <NavigateBreadcrumbs
                alias={alias}
                display={fileModel[alias].display}
                id={`${detail.data[`${fileModel[alias].prefix}_name`]} - v${detail.data[`${fileModel[alias].prefix}_version`]}`}
                unitId={unitId}
                unitSN={unitSN}
                cameraId={cameraId}
                cameraSN={cameraSN}
            />

        </Grid>
        <Grid item xs={12} sx={{mb: 3}}>
            <Typography
                variant='h4'>{`${fileModel[alias].display} ${detail.data[`${fileModel[alias].prefix}_name`]} - v${detail.data[`${fileModel[alias].prefix}_version`]}`}</Typography>
        </Grid>
        <Grid item xs={4} sm={3} lg={2} sx={{mb: 5}}>
            {permissions.delete && <DeleteComponent
                alias={alias}
                id={id}
                navigate={navigate}
                setError={setDeleteError}
            />}
        </Grid>
        <Grid item xs={4} sm={6} lg={8}/>
        <Grid item xs={4} sm={3} lg={2} sx={{mb: 5, display: "flex", justifyContent: "flex-end"}}>
            <Button
                variant='contained'
                startIcon={<FileDownloadIcon/>}
                onClick={handleDownload}
            >
                Download
            </Button>
        </Grid>
        <Grid item xs={12} key="file-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={permissions.edit ? <IconButton onClick={() => setEdit(!edit)}>
                        <EditIcon/>
                    </IconButton> : null}
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias={alias}
                        id={id}
                        data={newData.data}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={fields}
                    />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} key="properties" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={permissions.edit ? <IconButton onClick={() => (setPopup(!popup))}>
                        <EditIcon/>
                    </IconButton> : null}
                    title={fields.relations[property].display}/>
                {newData[property].length === 0 ? <CardContent>
                    <IconButton disableRipple>
                        <FormatListBulletedIcon sx={{fontSize: 50}}/>
                    </IconButton>
                    <Typography variant="caption" color="grey">
                        No additional information
                    </Typography>
                </CardContent> : <CardContent>
                    <Grid container
                          spacing={1}
                          sx={{margin: 'auto', mb: 2}}
                          alignItems="center"
                          justifyContent="center"
                    >
                        {newData[property].map(([, [key, value, desc]]) => ([<Grid item xs={5} key={key} sx={{mb: 1}}>
                            <Tooltip title={desc} arrow>
                                <Typography variant="body1" color={grey[700]}>
                                    {key}
                                </Typography>
                            </Tooltip>
                        </Grid>, <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                            <Tooltip title={desc} arrow placement="bottom-start">
                                <Typography variant="body1">
                                    {value}
                                </Typography>
                            </Tooltip>
                        </Grid>]))}
                    </Grid>
                </CardContent>}
            </Card>
        </Grid>
        <PopupDialog
            open={popup}
            content={<RelationEdit
                fields={fields}
                alias={alias}
                display={fileModel[alias].display}
                id={id}
                relation={property}
                refresh={fetchDetail}
                defaultValue={newData[property].map(([id, [key, value]]) => ([id, `${key}: ${value}`]))}
                setEdit={(value) => (setPopup(value))}
            />}
            handleClose={() => (setPopup(false))}
        />

    </Grid>
}