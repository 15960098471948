import Cookies from "js-cookie";
import {routes, useGetDataList, useSearch} from "../routes/api";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AccountListHeaders} from "../util/getFields";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {Grid, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import SearchBox from "../components/searchBox";
import {accountSearchFields} from "../util/searchFields";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {PAGE_SIZE} from "../util/util";
import {accountFields, userFields} from "../util/modelFields";
import CustomToolbar from "../components/GridToolbar";

export default function AccountList() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).account :
        {create: false, edit: false, delete: false};
    const route = routes.account;
    const allFields = {...accountFields.fields, ...userFields.fields};

    const [noData, setNoData] = useState(false);
    const [viewData, setViewData] = useState([]);
    const viewHeaders = AccountListHeaders.filter(key => key !== 'id').map((key) =>
        ({
            field: key,
            headerName: allFields[key].display,
            type: allFields[key].type === 'boolean' ? 'boolean' : 'string',
            minWidth: 100,
            flex: 1,
            hideable: key !== 'username',
            valueGetter: (value) => (
                typeof value === 'boolean' ?
                    (value ?
                        'Yes' :
                        'No')
                    : (Array.isArray(value) ?
                        value.reduce((acc, item) => (acc ? `${acc}, ${item}` : item), '') :
                        value)
            )
        }));

    const [totalPages, setTotalPages] = useState(null);

    const {data, loading, statusCode: status, handleCall} = useGetDataList(token, route);
    const {
        data: searchData,
        loading: searchLoading,
        statusCode: searchStatus,
        handleCall: searchCall
    } = useSearch(token, route);


    const navigate = useNavigate();
    const location = useLocation();

    const [paginationModel, setPaginationModel] = useState({
        page: -1,
        pageSize: PAGE_SIZE,
    });

    const [searchParams, setSearchParams] = useState({});
    const [searching, setSearching] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        a_phone: false,
        a_type: false,
        is_staff: false,
    });

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (location?.state?.from?.pathname?.split('/')[1] === 'account' || !location?.state?.from?.pathname) {
            setPaginationModel({...paginationModel, page: Number(sessionStorage.getItem('accountPage'))});
            setSearchParams(JSON.parse(sessionStorage.getItem('accountSearchParams')) || {});
            setSearching(sessionStorage.getItem('accountSearching') === 'T');
            setOpenSearch(sessionStorage.getItem('accountOpenSearch') === 'T');
            setColumnVisibilityModel(JSON.parse(sessionStorage.getItem('accountColumns')) || {
                a_phone: false,
                a_type: false,
                is_staff: false,
            });
        } else {
            setPaginationModel({...paginationModel, page: 0});
            sessionStorage.setItem('accountPage', '0');
            sessionStorage.setItem('accountSearchParams', '{}');
            sessionStorage.setItem('accountSearching', 'F');
            sessionStorage.setItem('accountOpenSearch', 'F');
            sessionStorage.setItem('accountColumns', JSON.stringify({
                a_phone: false,
                a_type: false,
                is_staff: false,
            }));
        }
    }, []);

    const handleReset = () => {
        setColumnVisibilityModel({
            a_phone: false,
            a_type: false,
            is_staff: false,
        });
        sessionStorage.setItem('accountColumns', JSON.stringify({
            a_phone: false,
            a_type: false,
            is_staff: false,
        }));
    };

    const fetchData = async () => {
        try {
            if (searching) {
                await searchCall({params: {...searchParams, page: paginationModel.page + 1}});
            } else {
                await handleCall({params: {page: paginationModel.page + 1}});
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        paginationModel.page >= 0 && fetchData();
    }, [paginationModel]);

    useEffect(() => {
        if (status === 204 || data?.count === 0 || data?.results?.length === 0) {
            setNoData(true);
        } else if (data?.results?.length > 0) {
            setViewData(data.results.map((item) => ({...item, ...item.a_user, id: item.id})));
            setNoData(false);
            setTotalPages(Math.ceil(data.count / PAGE_SIZE));
        } else {
            setNoData(false);
        }
    }, [data, status]);

    useEffect(() => {
        if (searchStatus !== 204 && searchData.results?.length > 0) {
            setFilteredData(searchData.results.map((item) => ({...item.a_user, id: item.id})));
            setTotalPages(Math.ceil(searchData.count / PAGE_SIZE));
        } else {
            setFilteredData([]);
            setTotalPages(0);
        }
    }, [searchData, searchStatus]);

    const handleRowClick = (param, event) => {
        event.preventDefault();
        window.history.replaceState({from: location}, '');
        navigate(`/account/${param.row.id}`, {state: {from: location}});
    };
    const handleEntry = (event) => {
        event.preventDefault();
        navigate(`/account/new`, {state: {from: location}});
    };

    const handleSearch = async () => {
        setFilteredData([]);
        setSearching(true);
        sessionStorage.setItem('accountSearching', 'T')
        setPaginationModel((pm) => ({...pm, page: 0}));
        sessionStorage.setItem('accountPage', '0');
    }

    const handleOpenSearch = () => {
        setOpenSearch(true);
        sessionStorage.setItem('accountOpenSearch', 'T');
    }

    const handleCancelSearch = () => {
        if (searching) {
            setPaginationModel((pm) => ({...pm, page: 0}));
            sessionStorage.setItem('accountPage', '0');
        }
        setOpenSearch(false);
        sessionStorage.setItem('accountOpenSearch', 'F');
        setSearching(false);
        sessionStorage.setItem('accountSearching', 'F');
        setSearchParams({});
        sessionStorage.setItem('accountSearchParams', '{}');
        setFilteredData([]);
    }

    const handlePageSelect = (e) => {
        setPaginationModel((pm) => ({...pm, page: e.target.value}));
        sessionStorage.setItem('accountPage', e.target.value);
    }

    const handlePaginationModelUpdate = (model, details) => {
        setPaginationModel(model);
        sessionStorage.setItem('accountPage', model.page);
    }

    const handleSearchParams = (params) => {
        sessionStorage.setItem('accountSearchParams', JSON.stringify(params));
        setSearchParams(params);
    }

    const handleColumnVisibilityLabel = (columns) => {
        sessionStorage.setItem('accountColumns', JSON.stringify(columns));
        setColumnVisibilityModel(columns);
    }

    if (((!searching && loading) || (searching && searchLoading))) return <LoadingComponent/>
    if (noData) return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justify="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account'/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {permissions.create &&
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button component="label"
                            variant="contained"
                            onClick={handleEntry}
                            startIcon={<AddIcon/>}
                    >
                        New Account
                    </Button>
                </Grid>}
            <Grid item xs={12}>
                <NoDataComponent/>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account'/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant='h4'>Accounts</Typography>
            </Grid>
            {permissions.create && <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    variant="contained"
                    onClick={handleEntry}
                    startIcon={<AddIcon/>}
                >
                    New Account
                </Button>
            </Grid>}
            <Grid item xs={9}/>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                    startIcon={<ManageSearchIcon/>}
                    sx={{mb: 1}}>
                    {openSearch ? "Cancel" : "Search"}
                </Button>
            </Grid>
            {openSearch && <SearchBox
                prefix='a'
                searchParams={searchParams}
                setSearchParams={handleSearchParams}
                searchFields={accountSearchFields}
                sendSearch={handleSearch}
            />}
            <Grid item xs={12}/>
            {searching && <Grid item xs={12}>
                <Typography variant='h6'>
                    Filtered Results:
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                <DataGrid
                    disableColumnFilter
                    disableDensitySelector
                    columns={viewHeaders}
                    rows={searching ? filteredData : viewData}
                    rowCount={(searching ? searchData.count : data.count) || 0}
                    paginationMode="server"
                    slots={{toolbar: () => <CustomToolbar handleReset={handleReset}/>}}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelUpdate}
                    pageSizeOptions={[PAGE_SIZE]}
                    autoHeight={true}
                    rowHeight={80}
                    onRowClick={handleRowClick}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityLabel}

                />
            </Grid>
            {Boolean(totalPages) &&
                <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                    <Typography sx={{mr: 1}}>Page: </Typography>
                    <Select
                        name='Page'
                        key='page'
                        onChange={handlePageSelect}
                        value={paginationModel.page}
                        sx={{minWidth: 70}}
                    >
                        {Array.from({length: totalPages}, (_, index) => (
                            <MenuItem key={index} value={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>}
        </Grid>
    )
}