import TextField from "@mui/material/TextField";
import {DatePicker, DateTimePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useContext, useState} from "react";
import {EnumContext} from "./enumContext";

export default function InputField({
    field,
    value,
    error,
    onChange,
    onDateChange,
    onBoolChange,
    onEnumChange,
    onBlur,
    regexCheck
}) {
    let content;
    const [showPassword, setShowPassword] = useState(false);
    let regexp;
    const {enums} = useContext(EnumContext);

    switch (field.type) {
        case 'text':
        case 'float':
            regexp = /.*/;
            if (field.regexPattern) {
                regexp = new RegExp(field.regexPattern);
            }
            content = <TextField
                fullWidth
                margin='normal'
                required={field.required}
                name={field.alias}
                label={field.display}
                type='text'
                id={field.alias}
                error={(field.required && !value) || error || regexCheck[field.alias]}
                multiline={field.multiline}
                rows={4}
                onChange={(e) => onChange(e, regexp)}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                helperText={field.helperText}
                onBlur={onBlur}
                value={value || ''}
                InputProps={{
                    endAdornment: field.placeholder && (
                        <InputAdornment position="end">
                            <Tooltip title={field.placeholder} arrow>
                                <IconButton size='0' disableRipple sx={{width: 30}}><InfoOutlinedIcon/></IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                sx={{maxWidth: 350}}
            />;
            break;
        case 'password':
            regexp = new RegExp(field.regexPattern);

            const handleClickShowPassword = () => {
                setShowPassword(!showPassword);
            };

            const handleMouseDownPassword = (e) => {
                e.preventDefault();
            };
            content = <TextField
                fullWidth
                margin='normal'
                required={field.required}
                name={field.alias}
                label={field.display}
                type={showPassword ? 'text' : 'password'}
                id={field.alias}
                error={(field.required && !value) || error || regexCheck[field.alias]}
                onChange={(e) => onChange(e, regexp)}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                helperText={field.helperText}
                onBlur={onBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{maxWidth: 350}}
            />;
            break;
        case 'int':
            content = <TextField
                fullWidth
                margin='normal'
                required={field.required}
                name={field.alias}
                label={field.display}
                type='number'
                id={field.alias}
                error={(field.required && !value) || error}
                onChange={onChange}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                helperText={field.helperText}
                onBlur={onBlur}
                inputProps={{step: 1}}
                value={value}
                sx={{maxWidth: 350}}
            />;
            break;
        case 'datetime':
            content = <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    fullWidth
                    id={field.alias}
                    name={field.alias}
                    label={field.display}
                    onChange={(value) => onDateChange(field.alias, value)}
                    defaultValue={dayjs(new Date())}
                    value={dayjs(value)}
                    sx={{maxWidth: 350}}
                />
            </LocalizationProvider>;
            break;
        case 'date':
            content = <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    fullWidth
                    id={field.alias}
                    name={field.alias}
                    label={field.display}
                    onChange={(value) => onDateChange(field.alias, value)}
                    defaultValue={dayjs(new Date())}
                    value={dayjs(value)}
                    sx={{maxWidth: 350}}
                />
            </LocalizationProvider>;
            break;
        case 'time':
            content = <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    fullWidth
                    id={field.alias}
                    name={field.alias}
                    label={field.display}
                    onChange={(value) => onDateChange(field.alias, value)}
                    defaultValue={dayjs(new Date())}
                    value={dayjs(value)}
                    sx={{maxWidth: 350}}
                />
            </LocalizationProvider>;
            break;
        case 'enum':
            content = <FormControl
                margin='normal'
                fullWidth
                sx={{maxWidth: 350}}
            >
                <InputLabel id={`${field.alias}_label`}>{field.display}</InputLabel>
                <Select
                    required={field.required}
                    name={field.alias}
                    label={field.display}
                    id={field.alias}
                    error={(field.required && !value) || error}
                    onChange={onEnumChange}
                    defaultValue={field.defaultValue}
                    onBlur={onBlur}
                    value={value}
                >
                    {Object.entries(enums[field.alias].data).map(([key, value]) => (
                        <MenuItem value={key}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>;
            break;
        case 'json':
            content = <TextField
                fullWidth
                margin='normal'
                required={field.required}
                name={field.alias}
                label={field.display}
                type='text'
                id={field.alias}
                error={(field.required && !value) || error}
                multiline={field.multiline}
                rows={4}
                value={value}
                onChange={onChange}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                helperText={field.helperText}
                onBlur={onBlur}
                sx={{maxWidth: 350}}
            />;
            break;
        case 'boolean':
            content = <FormControlLabel
                control={<Switch/>}
                name={field.alias}
                id={field.alias}
                label={field.display}
                required={field.required}
                checked={value}
                onChange={onBoolChange}
            />;
            break;
        default:
            content = <TextField
                fullWidth
                margin='normal'
                name={field.alias}
                label={field.display}
                type={field.alias}
                id={field.alias}
                helperText={field.helperText}
                placeholder={field.placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                sx={{maxWidth: 350}}
            />;
    }
    return content;

}