import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {FormControlLabel, FormGroup, Grid, Paper, Slider, Switch} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {timestampToTimeString} from "../util/util";

const unitToTime = {
    week: 1000 * 60 * 60 * 24 * 7,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
    second: 1000,
}


const filterTime = (obj, timeDiff, unit) => {
    if (obj.time) {
        const now = new Date();
        const timestamp = new Date(obj.time);
        return (now - timestamp) / unitToTime[unit] <= timeDiff
    }
    return false
}

function stringToHexColor(str, rgb = false, alpha = 0.08) {
    let hash = 0x811c9dc5; // 32-bit FNV-1a hash initialization
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash = (hash * 0x01000193) >>> 0; // Multiply by FNV prime and ensure it's a 32-bit number
    }

    let hexColor = '';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        hexColor += ('00' + value.toString(16)).slice(-2);
    }
    if (rgb) {
        let bigint = parseInt(hexColor, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `#${hexColor}`;
}

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{p: 1}}>
                <Typography variant='body2' key='datetime' gutterBottom>{payload[0].payload.datetime}</Typography>
                {payload.map(item => <Typography variant='body2'
                                                 key={item.name}>{item.name} : {item.value}</Typography>)}
            </Paper>
        );
    }

    return null;
};

export function RenderChart({data}) {
    const [unit, setUnit] = useState('seconds');
    const [timeRange, setTimeRange] = useState([new Date(data[data.length - 1].datetime).getTime() - 30 * 60000, new Date(data[data.length - 1].datetime).getTime()]);
    const [objects, setObjects] = useState(
        Object.values(data[0].results).reduce((acc, value) => ({...acc, [value.label]: true}), {}));
    const dataKey = Object.values(data[0]).filter(value => value.label && value.value >= 0).map(value => value.label);

    const [viewData, setViewData] = useState([]);

    useEffect(() => {
        const savedObjects = JSON?.parse(sessionStorage.getItem('objects'));
        const savedTimeRange = JSON?.parse(sessionStorage.getItem('timeRange'));
        if (savedObjects) {
            setObjects(savedObjects);
        }
        if (savedTimeRange) {
            setTimeRange(savedTimeRange);
        }
    }, []);

    useEffect(() => {
        const allData = data.map(datum => (
            Object.values(datum.results).filter((value) => value.value && value.value >= 0).reduce((acc, value) => (
                {...acc, [value.label]: value.value}
            ), {time: datum.time, datetime: datum.datetime})
        ));
        setViewData(
            allData.filter(datum => new Date(datum.datetime).getTime() >= timeRange[0] && new Date(datum.datetime).getTime() <= timeRange[1])
        );
    }, [data, timeRange]);

    const handleSelectionChange = (event) => {
        const newObject = {
            ...objects,
            [event.target.name]: event.target.checked,
        }
        setObjects(newObject);
        sessionStorage.setItem('objects', JSON.stringify(newObject));
    };

    const handleSliderChange = (event, newValue) => {
        setTimeRange(newValue);
        sessionStorage.setItem('timeRange', JSON.stringify(newValue));

    };

    return <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item alignItems="center">
            <Typography variant="overline">Time Range</Typography>
        </Grid>
        <Grid item xs={6}>
            <Slider
                value={timeRange}
                onChange={handleSliderChange}
                min={new Date(data[0].datetime).getTime()}
                max={new Date(data[data.length - 1].datetime).getTime()}
                aria-labelledby="date-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => (timestampToTimeString(value, true).datetime)}
            />
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <FormGroup row>
                {dataKey.map((key) => (
                    <FormControlLabel
                        control={<Switch/>}
                        checked={objects[key]}
                        onChange={handleSelectionChange}
                        name={key}
                        label={key}
                        key={key}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: stringToHexColor(key),
                                '&:hover': {
                                    backgroundColor: stringToHexColor(key, true),
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: stringToHexColor(key),
                            },
                        }}
                    />
                ))}
            </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <ResponsiveContainer width="100%" minHeight={500}>
                <LineChart data={viewData} minWidth={450} minHeight={300}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
                    <XAxis dataKey="time" padding={{left: 10, right: 10}}/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    {dataKey.map((dataKey) => (
                        <Line key={dataKey} name={dataKey} type="monotone" dataKey={dataKey}
                              stroke={stringToHexColor(dataKey)}
                              strokeWidth={2} hide={!objects[dataKey]} connectNulls/>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="overline" gutterBottom>all timestamps are in user timezone</Typography>
        </Grid>
    </Grid>
}